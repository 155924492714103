import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { useQueryClient } from '@tanstack/react-query'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import { format } from 'date-fns'
import { usePosts, useRemovePost } from '@/hooks/usePost'
import { AdminDataTable } from '@/components/TestDataTable'
import { Link } from 'react-router-dom'
import { copyToClipboard } from '@/lib/copyToClipboard'
import { toast } from 'react-toastify'

function PostsSection() {
    const searchRef = useRef()
    const [search, setSearch] = useState()
    const queryClient = useQueryClient()
    const [isCopied, setIsCopied] = useState(false)

    let { data, isSuccess, fetchNextPage, isFetchingNextPage } = usePosts(searchRef)
    console.log(data)

    const removePost = useRemovePost()


    const columns = [
        // {
        //     id: "select",
        //     header: ({ table }) => (
        //         <Checkbox
        //             checked={
        //                 table.getIsAllPageRowsSelected() ||
        //                 (table.getIsSomePageRowsSelected() && "indeterminate")
        //             }
        //             onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        //             aria-label="Select all"
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <Checkbox
        //             checked={row.getIsSelected()}
        //             onCheckedChange={(value) => row.toggleSelected(!!value)}
        //             aria-label="Select row"
        //         />
        //     ),
        //     enableSorting: false,
        //     enableHiding: false,
        // },

        {
            accessorKey: "_id",
            header: ({ column }) => {
                return (
                    <div className="flex items-center cursor-pointer select-none"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Post Id
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </div>
                )
            },
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("_id")}</div>
            ),
        },

        {
            header: "Type",
            cell: ({ row }) => (
                <div>{row.original.type} post </div>
            ),
        },


        {
            header: "Posted By",
            cell: ({ row }) => {
                console.log(row.original)
                return <div>@{(row.original.target?.username || row.original.target?.handle) || "Deleted"}</div>
            }
        },


        {
            accessorKey: "createdAt",
            header: "Created At",
            cell: ({ row }) => (
                <div className="capitalize">{format(row.getValue("createdAt"), 'MMM d, yyy h:mm a')}</div>
            ),
        },

        {
            id: "actions",
            header: "Action",
            enableHiding: false,
            cell: (data) => {
                let path = "?type=" + data?.row?.original?.type
                console.log(path)
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                        <Link to={"https://www.freedombook.co/post/" + data.row.original?._id + path} target="_blank" rel="noopener noreferrer" >
                                <DropdownMenuItem className='p-2 bg-card cursor-pointer hover:bg-accent px-4 border border-accent'>View</DropdownMenuItem>
                            </Link>
                            <DropdownMenuItem className='p-2 bg-card cursor-pointer hover:bg-accent px-4 border border-accent' onClick={() => {
                                let postId = data.row.original?._id
                                let media = data.row.original?.media
                                console.log(media, postId)
                                removePost.mutate({ postId, media })
                            }}>Remove</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]


    const debouncedSearch = debounce((value) => {
        setSearch(value)
    }, 300);
    console.log(data)

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, []);


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(e.target.value);
    };

    const _fetchNextPage = () => {
        fetchNextPage()
    }

    useEffect(() => {
        searchRef.current = search
        queryClient.invalidateQueries({ queryKey: ["usersPostsAdmin"] });
    }, [search])

    return (
        <main className="w-full overflow-auto px-8 py-4">
            <AdminDataTable searchText={"Search by id"} filterValue="Posted By" title={"Posts"} filter={true} columns={columns} data={isSuccess && data} handleSearchChange={handleSearchChange} fetchNextPage={_fetchNextPage} />
        </main>
    )
}

export default React.memo(PostsSection)