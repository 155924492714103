import Profile from "@/components/profile/Profile"
import { Textarea } from "@/components/ui/textarea"
import { domain } from "@/config/domain"
import { copyToClipboard } from "@/lib/copyToClipboard"
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar"
import { format } from "date-fns"
import { Copy } from "lucide-react"
import { useState } from "react"
import { MdCancel, MdClose } from "react-icons/md"
import { Link, replace, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

function ReportModel({ setReportModelState, reportDetails }) {
    console.log(reportDetails)
    const [isCopied, setIsCopied] = useState(false);

    const navigate = useNavigate()

    return (
        <div className='fixed inset-0 z-50  w-screen sm:p-8 overflow-hidden h-screen flex items-center justify-center' >
            <div className='absolute top-0 right-0  backdrop-blur-[1.5px]  w-full h-full' onClick={() => {
                setReportModelState(false)
                navigate("", { replace: true })
            }}></div>
            <div className='flex flex-col gap-4 z-10 p-4 max-w-[400px] w-full bg-background rounded-lg h-fit overflow-auto border-accent border'>
                <div className="flex flex-col gap-2">
                    <div className="w-full flex justify-between">
                        <span>Report Id</span>
                        <MdClose className="cursor-pointer" size={28} onClick={() => {
                            navigate("", { replace: true })
                        }}></MdClose>
                    </div>
                    <div className="flex gap-2">
                        <span>{reportDetails?._id}</span>
                        <Copy cursor="pointer" onClick={() => {
                            if (!isCopied) {
                                copyToClipboard(reportDetails?._id, setIsCopied)
                                setIsCopied(true)
                                return
                            }
                        }} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <span>Post Id</span>
                        <div className="flex gap-2">
                            <span>{reportDetails?.postId}</span>
                            <Copy cursor="pointer" onClick={() => {
                                if (!isCopied) {
                                    copyToClipboard(reportDetails?.postId, setIsCopied)
                                    setIsCopied(true)
                                    return
                                }
                            }} />
                        </div>
                    </div>
                        <span className="text-lg">Reported By</span>
                        <Link to={domain + "/user/" + reportDetails?.reportedBy[0]?.username} className="flex gap-2 items-center">
                            <div className='w-12 h-12 bg-accent flex items-center justify-center  rounded-full overflow-hidden'>
                                <Avatar className="sm:flex">
                                    <AvatarImage src={reportDetails.reportedBy[0]?.profile} alt="Avatar" />
                                    <AvatarFallback >{reportDetails.reportedBy[0].firstname[0]}</AvatarFallback>
                                </Avatar>
                            </div>
                            <div className="flex flex-col" >
                                <div>{reportDetails?.reportedBy[0]?.lastname ? reportDetails?.reportedBy[0]?.firstname + " " + reportDetails?.reportedBy[0]?.lastname : reportDetails?.reportedBy[0]?.firstname} </div>
                                <div className="text-sm text-gray-200">@{reportDetails?.reportedBy[0]?.username}</div>
                            </div>
                        </Link>
                    </div>
                    <div className="flex flex-col gap-1">
                        <span>Report Date</span>
                <div className="capitalize">{format(reportDetails?.createdAt, 'MMM d, yyy h:mm a')}</div>

                    </div>
                    <div className="flex flex-col gap-1">
                        <span>Type</span>
                        <span>{reportDetails?.type}</span>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span>Message</span>
                        <p>{reportDetails.reportMessage} </p>
                    </div>
                </div>
            </div>
            )
}

            export default ReportModel


