import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { copyToClipboard } from "@/lib/copyToClipboard"
import { Label } from "@radix-ui/react-dropdown-menu"
import { format } from "date-fns"
import { Camera, Copy } from "lucide-react"
import { FC, useState} from "react"
import { replace, useNavigate } from "react-router-dom"

const PageModel: FC<any> = ({ setModelTrigger, pageDetails }) => {
    const [isCopied, setIsCopied] = useState(false)
    const navigate = useNavigate()

    return (
        <div className='absolute top-0 right-0 w-screen z-50 sm:p-8 overflow-hidden h-screen flex items-center justify-center'>
            <div className='absolute top-0 right-0 backdrop-blur-[1.5px] w-full h-full' onClick={() => {
                navigate("", {replace: true})
            }}></div>
            <div className='z-10 w-fit bg-background rounded-lg h-full  border-2 border-accent shadow-md overflow-auto'>
                <div className="grid gap-8 p-1  overflow-y-auto relative">
                    <div className="flex flex-col items-center justify-center relative">
                        {/* cover image */}
                        <div className='relative max-w-[460px] max-h-72 roundd-md  overflow-hidden'>
                            <div >
                                <label htmlFor="image-cover" className="cursor-pointer">
                                    <div className="flex items-center justify-center w-[440px] h-56 bg-muted">
                                        {
                                            pageDetails?.cover ? <img className='w-full' onClick={() => {
                                            }} src={pageDetails?.cover} alt="" />
                                                :
                                                <Camera size={42} />
                                        }
                                    </div>
                                </label>
                            </div>

                        </div>
                        {/* profile image */}
                        <div className='w-28 h-28 rounded-xl absolute -bottom-16 border-primary border-2 overflow-hidden'>
                            <div >
                                <label htmlFor="image-profile" className="cursor-pointer">
                                    <div className="flex items-center justify-center w-28 h-28 bg-muted">
                                        {
                                            pageDetails?.profile ? <img className='w-full' onClick={() => {
                                            }} src={pageDetails?.profile} alt="" />

                                                :
                                                <Camera size={42} />
                                        }
                                    </div>
                                </label>
                            </div>

                        </div>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <div className="w-full p-4 flex flex-col gap-8 items-center">
                            <div className="flex flex-col w-full justify-center gap-2 mt-10">
                            <div>

                            <Label className="flex gap-2 py-2">
                                        <span>Page Id</span>
                                        <Copy size={20} cursor="pointer" onClick={() => {
                                            if (!isCopied) {
                                                copyToClipboard(pageDetails?._id, setIsCopied)
                                                setIsCopied(true)
                                                return
                                            }
                                        }} />
                                    </Label>
                                    <Input
                                        name="id"
                                        disabled={true}
                                        defaultValue={pageDetails?._id}
                                        id="id"
                                        className="w-96"
                                        placeholder="Page Id"
                                    />
                                </div>

                                <div>
                                    <Label >
                                        Name
                                    </Label>
                                    <Input
                                        name="name"
                                        disabled={true}
                                        defaultValue={pageDetails?.name}
                                        id="name"
                                        className="w-96"
                                        placeholder="Page Name"
                                    />
                                </div>


                                <div>
                                    <Label >
                                        Created At
                                    </Label>
                                    <Input
                                        name="create
                                        disabled={true}dat"
                                        defaultValue={format(pageDetails.createdAt, 'MMM d, yyy h:mm a')}
                                        id="createdat"
                                        className="w-96"
                                        placeholder="CreatedAt"
                                    />
                                </div>

                                <div>
                                    <Label >
                                        Page Handle
                                    </Label>
                                    <Input
                                        name="handle
                                        disabled={true}"
                                        defaultValue={pageDetails?.handle}
                                        id="handle"
                                        className="w-96"
                                        placeholder="@page_handle"
                                    />
                                </div>


                                <div>
                                    <Label >
                                        Page Bio
                                    </Label>
                                    <Input
                                        name="bio"
                                        disabled={true}
                                        defaultValue={pageDetails?.bio}
                                        id="bio"
                                        className="w-96"
                                        placeholder="bio"
                                    />
                                </div>
                                <div>
                                    <Label >
                                        About
                                    </Label>
                                    <Textarea
                                    disabled={true}
                                        defaultValue={pageDetails?.about}
                                        id="about"
                                        className="w-96"
                                        placeholder="your page about"
                                        maxLength={150}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PageModel