import { createBrowserRouter } from "react-router-dom";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import DashboardSection from "./sections/Dashboard";
import { AdminDashboard } from "./AdminDashboard";
import Users from "./sections/Users";
import PostsSection from "./sections/PostsSection";
import GroupsSection from "./sections/Groups";
import Reports from "./sections/Reports.";
import PublicRoute from "./components/PublicRoute";
import { AdminLogin } from "./pages/Login";
import Pages from "./sections/Pages";
import Campaigns from "./sections/Campaigns";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <AdminProtectedRoute />,
        children: [
            {
                path: "/",
                element: <AdminDashboard children={<DashboardSection />} />,
                index: true
            },
            {
                path: 'userss',
                element: <Users />
            },
            {
                path: "/users",
                element: <AdminDashboard children={<Users />} />,
            },
            {
                path: "/posts",
                element: <AdminDashboard children={<PostsSection />} />,
            },
            {
                path: "/groups",
                element: <AdminDashboard children={<GroupsSection />} />,
            },
            {
                path: "/pages",
                element: <AdminDashboard children={<Pages />} />,
            },
            {
                path: "/reports",
                element: <AdminDashboard children={<Reports />} />,
            },
            {
                path: "/campaigns",
                element: <AdminDashboard children={<Campaigns />} />,
            },
        ]
    },
    {
        path: "/",
        element: <PublicRoute />,
        children: [
            {
                path: "/login",
                element: <AdminLogin />,
                index: true
            },
        ],

    }
])
