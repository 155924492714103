import { AdminDataTable } from '@/components/TestDataTable'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { useQueryClient } from '@tanstack/react-query'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { useGroups, useRemoveGroup } from '@/hooks/useGroup'
import { useChatGroups, useRemoveChatGroup } from '@/hooks/useChatGroup'
import { format } from 'date-fns'
import { Link, useSearchParams } from 'react-router-dom'
import GroupModel from '@/models/GroupModel'
import ChatGroup from '@/models/ChatGroupModel'

function GroupsSection() {
    const searchRef = useRef()
    const [search, setSearch] = useState()
    const queryClient = useQueryClient()
    const [activeGroup, setActiveGroup] = useState<string>('community')

    const groupData = useGroups(searchRef)
    const chatGroupData = useChatGroups(searchRef)

    const removeGroup = useRemoveGroup()
    const removeChatGroup = useRemoveChatGroup()

    const groupColumns = [
        // {
        //     id: "select",
        //     header: ({ table }) => (
        //         <Checkbox
        //             checked={
        //                 table.getIsAllPageRowsSelected() ||
        //                 (table.getIsSomePageRowsSelected() && "indeterminate")
        //             }
        //             onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        //             aria-label="Select all"
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <Checkbox
        //             checked={row.getIsSelected()}
        //             onCheckedChange={(value) => row.toggleSelected(!!value)}
        //             aria-label="Select row"
        //         />
        //     ),
        //     enableSorting: false,
        //     enableHiding: false,
        // },
        {
            accessorKey: "_id",
            header: ({ column }) => {
                return (
                    <div className="flex items-center cursor-pointer select-none"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Group Id
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </div>
                )
            },
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("_id")}</div>
            ),
        },
        {
            accessorKey: "handle",
            header: "Handle",
            cell: ({ row }) => (
                <div className="capitalize">@{row.original?.handle}</div>
            ),
        },
        {
            header: "Owner",
            cell: ({ row }) => (
                <div>@{(row.original.user.length > 0 && row.original.user[0].username) || "Deleted"}</div>
            ),
        },

        {
            accessorKey: "createdAt",
            header: "Created At",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("createdAt") ? format(row.getValue("createdAt"), 'MMM d, yyy h:mm a') : null}</div>
            ),


        },

        {
            id: "actions",
            header: "Action",
            enableHiding: false,
            cell: (data) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                                <Link to={"https://freedombook.co/group/" + data.row.original?.handle} target="_blank" rel="noopener noreferrer" >
                                    <DropdownMenuItem className='p-2 bg-card cursor-pointer hover:bg-accent px-4 border border-accent'>View</DropdownMenuItem>
                                </Link>
                            <DropdownMenuItem className='p-2 bg-card cursor-pointer hover:bg-accent px-4 border border-accent' onClick={() => {
                                let groupId = data.row.original?._id
                                removeGroup.mutate({ groupId })
                            }}>Remove</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    const chatGroupColumns = [
        // {
        //     id: "select",
        //     header: ({ table }) => (
        //         <Checkbox
        //             checked={
        //                 table.getIsAllPageRowsSelected() ||
        //                 (table.getIsSomePageRowsSelected() && "indeterminate")
        //             }
        //             onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        //             aria-label="Select all"
        //         />
        //     ),
        //     cell: ({ row }) => (
        //         <Checkbox
        //             checked={row.getIsSelected()}
        //             onCheckedChange={(value) => row.toggleSelected(!!value)}
        //             aria-label="Select row"
        //         />
        //     ),
        //     enableSorting: false,
        //     enableHiding: false,
        // },
        {
            accessorKey: "_id",
            header: ({ column }) => {
                return (
                    <div className="flex items-center cursor-pointer select-none"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Group Id
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </div>
                )
            },
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("_id")}</div>
            ),
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("name")}</div>
            ),
        },
        {
            accessorKey: "username",
            header: "Owner",
            cell: ({ row }) => (
                <div>@{row.original.user[0].username}</div>
            ),
        },

        {
            accessorKey: "createdAt",
            header: "Created At",
            cell: ({ row }) => (
                <div className="capitalize">{format(row.getValue("createdAt"), 'MMM d, yyy h:mm a')}</div>
            ),


        },

        {
            id: "actions",
            header: "Action",
            enableHiding: false,
            cell: (data) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuItem className='p-2 bg-card cursor-pointer hover:bg-accent px-4 border border-accent' onClick={() => {
                                let groupId = data.row.original?._id
                                removeChatGroup.mutate({ groupId })
                            }}>Remove</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    // const { data, isSuccess } = useGroups('')

    // if (isSuccess) {
    //     console.log(data, isSuccess)
    // }



    const debouncedSearch = debounce((value) => {
        setSearch(value)
    }, 300);

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, []);


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(e.target.value);
    };

    const _fetchNextPage = (fetchNextPage) => {
        if (fetchNextPage) {
            fetchNextPage()
        }
    }

    useEffect(() => {
        searchRef.current = search
        if (activeGroup == 'community') {
            queryClient.invalidateQueries({ queryKey: ["groupsAdmin"] });
        } else {
            queryClient.invalidateQueries({ queryKey: ["chatGroupsAdmin"] });
        }
    }, [search])


    const [groupModelState, setGroupModelState] = useState(false)
    const [groupIndex, setGroupIndex] = useState(-1)
    const [searchParams] = useSearchParams()

    return (
        <main className="w-full px-8 py-4 overflow-y-scroll">
            {searchParams.get("model") == 'community' && groupIndex > -1 && <GroupModel setModelTrigger={setGroupModelState} groupDetails={groupData?.data[groupIndex]} />}
            {searchParams.get("model") == 'chat' && groupIndex > -1 && <ChatGroup setModelTrigger={setGroupModelState} groupDetails={chatGroupData?.data[groupIndex]} />}

            <Tabs defaultValue="community" className="w-full  h-full">
                <TabsList className="flex gap-2 w-56 grid-cols-2">
                    <TabsTrigger
                        onClick={() => {
                            if (activeGroup !== "community") {
                                setActiveGroup("community")
                            }
                        }} className={`${activeGroup == "community" && "bg-primary text-white"} rounded-md py-2 px-4 border-[1px] border-muted`} value="community">Community</TabsTrigger>

                    <TabsTrigger
                        onClick={() => {
                            if (activeGroup !== "chat") {
                                setActiveGroup("chat")
                            }
                        }}
                        className={`${activeGroup == "chat" && "bg-primary text-white"} rounded-md py-2 px-4 border-[1px] border-muted`} value="chat">Chat</TabsTrigger>
                </TabsList>
                <TabsContent value="community" className="h-full">
                    <AdminDataTable searchText={"Id or handle"} setModelState={setGroupModelState} setItemIndex={setGroupIndex} title={"Groups"} navigation={"?model=community"} filterValue={"_id"} filter={true} columns={groupColumns} handleSearchChange={handleSearchChange} fetchNextPage={_fetchNextPage} useGroups={useGroups} data={!groupData.isLoading && groupData?.data} />

                </TabsContent>
                <TabsContent value="chat" className="h-full">
                    <AdminDataTable searchText={"Search by Id"} title={"Chat Groups"} setModelState={setGroupModelState} setItemIndex={setGroupIndex} navigation={"?model=chat"} filterValue={"_id"} filter={true} columns={chatGroupColumns} handleSearchChange={handleSearchChange} fetchNextPage={_fetchNextPage} useGroups={useChatGroups} data={!chatGroupData.isLoading && chatGroupData.data} />
                </TabsContent>
            </Tabs>
        </main>
    )
}

export default GroupsSection