import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Link, useNavigate } from "react-router-dom"
import { LoginUserSchema } from "@/utils/schemas/auth"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { useAppDispatch } from "@/app/hooks"
import { axiosClient } from "@/api/axiosClient"
import { setAccessToken } from "@/app/features/admin/authSlice"
import { setAdmin } from "@/app/features/admin/adminSlice"
import { Eye, EyeOff } from "lucide-react"
import { useState } from "react"
import profile from '../assets/logo.png'


export function AdminLogin() {
    const { register, handleSubmit } = useForm({ resolver: zodResolver(LoginUserSchema) })
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const loginAdmin = async (data: any) => {
        const response = await axiosClient.post("/admin/login", data, { withCredentials: true })
        return response.data
    }

    const mutation = useMutation({
        mutationFn: async (data): Promise<any> => {
            return await loginAdmin(data)
        },
        onError: (e) => {
            console.log(e)
        }
    })

    const dispatchUser = async () => {
        console.log(mutation.data.user)
        dispatch(setAccessToken(mutation.data.access_token))
        dispatch(setAdmin(mutation.data.user))
    }

    const onSubmit = async (data) => {

        mutation.mutate(data)
    }
    if (mutation.isSuccess) {
        dispatchUser()
        navigate("/")
    }

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  

    return (
        <div className="flex flex-col gap-10 items-center justify-center w-screen h-screen">
                        <h1 className="text-2xl font-bold "><img className="h-16 sm:h-20" src={profile} alt="" /></h1>
            <Card className="mx-auto max-w-md w-full">
                <CardHeader>
                    <CardTitle className="text-2xl">Admin Login</CardTitle>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid gap-4">
                            <div className="grid gap-2">
                                <Label htmlFor="username">Username</Label>
                                <Input
                                    id="username"
                                    type="text"
                                    placeholder="username"
                                    {...register("username")}
                                    required
                                />
                            </div>
                            <div className="relative grid gap-2">
                                <Input id="password" type={showPassword ? "text" : "password"} placeholder="Password" {...register("password")} required />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="icon"
                                    className="absolute inset-y-0 hover:bg-none right-0 pr-3 flex items-center"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? (
                                        <EyeOff className="h-4 w-4 text-gray-500" />
                                    ) : (
                                        <Eye className="h-4 w-4 text-gray-500" />
                                    )}
                                </Button>
                            </div>
                            <Button type="submit" className="w-full">
                                Login
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}
