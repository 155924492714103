import { SimpleCard } from '@/components/Card'
import { AdminDataTable } from '@/components/TestDataTable'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { useDashboardData } from '@/hooks/useAdmin'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { format } from 'date-fns'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'

// const users: User[] = [
//   {
//     id: 'asdfewer',
//     username: 'shazz',
//     fullname: "shahzad ali",
//     email: "shazz@gmail.com",
//     accountStatus: "terminated" || "deactivated" || "active",
//     accountCreationDate: "12 april 2024"
//   },
//   {
//     id: 'asdfewer',
//     username: 'shazz',
//     fullname: "shahzad ali",
//     email: "shazz@gmail.com",
//     accountStatus: "terminated" || "deactivated" || "active",
//     accountCreationDate: "12 april 2024"
//   },
//   {
//     id: 'asdfewer',
//     username: 'shazz',
//     fullname: "shahzad ali",
//     email: "shazz@gmail.com",
//     accountStatus: "terminated" || "deactivated" || "active",
//     accountCreationDate: "12 april 2024"
//   },
// ]


// const campainColumns = [
// //   {
// //       id: "select",
// //       header: ({ table }) => (
// //           <Checkbox
// //               checked={
// //                   table.getIsAllPageRowsSelected() ||
// //                   (table.getIsSomePageRowsSelected() && "indeterminate")
// //               }
// //               onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
// //               aria-label="Select all"
// //           />
// //       ),
// //       cell: ({ row }) => (
// //           <Checkbox
// //               checked={row.getIsSelected()}
// //               onCheckedChange={(value) => row.toggleSelected(!!value)}
// //               aria-label="Select row"
// //           />
// //       ),
// //       enableSorting: false,
// //       enableHiding: false,
// //   },
//   {
//       accessorKey: "name",
//       header: "User",
//       cell: ({ row }) => (
//           <div className="capitalize">{row.original?.firstname + " " + row.original?.lastname}</div>
//       ),
//   },
//   {
//       accessorKey: "username",
//       header: "Username",
//       cell: ({ row }) => (
//           <div>@{row.getValue("username")}</div>
//       ),
//   },

//   {
//       accessorKey: "email",
//       header: "Email",
//       cell: ({ row }) => (
//           <div className="capitalize">{row.getValue("email")}</div>
//       ),
//   },
//   {
//       accessorKey: "isActive",
//       header: "Status",
//       cell: ({ row }) => {
//           const isActive = row.getValue("isActive")
//           const isSuspended = row.original?.isSuspended

//           let status = isSuspended ? "suspended" : isActive ? 'Active' : 'Deactivated'
//           return <div className="capitalize">{status}</div>
//       }

//   },


//   {
//       accessorKey: "createdAt",
//       header: "Acount Creation Date",
//       cell: ({ row }) => (
//           <div></div>
//           // <div className="capitalize">{format(row.getValue("createdAt"), 'MMM d, yyy h:mm a')}</div>
//       ),
//   },
// ]


const campaignColumns = [
    // {
    //     id: "select",
    //     header: ({ table }) => (
    //         <Checkbox
    //             checked={
    //                 table.getIsAllPageRowsSelected() ||
    //                 (table.getIsSomePageRowsSelected() && "indeterminate")
    //             }
    //             onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //             aria-label="Select all"
    //         />
    //     ),
    //     cell: ({ row }) => (
    //         <Checkbox
    //             checked={row.getIsSelected()}
    //             onCheckedChange={(value) => row.toggleSelected(!!value)}
    //             aria-label="Select row"
    //         />
    //     ),
    //     enableSorting: false,
    //     enableHiding: false,
    // },
    {
        accessorKey: "_id",
        header: ({ column }) => {
            return (
                <div className="flex items-center cursor-pointer select-none"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Campaign Id
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </div>
            )
        },
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("_id")}</div>
        ),
    },
    {
        accessorKey: "username",
        header: "Username",
        cell: ({ row }) => (
            <div>@{row.getValue("username")}</div>
        ),
    },

    // {
    //     accessorKey: "email",
    //     header: "Email",
    //     cell: ({ row }) => (
    //         <div className="capitalize">{row.getValue("email")}</div>
    //     ),
    // },
    {
        accessorKey: "isActive",
        header: "Status",
        cell: ({ row }) => {
            const isActive = row.getValue("isActive")
            const isSuspended = row.original?.isSuspended

            let status = isSuspended ? "suspended" : isActive ? 'Active' : 'Deactivated'
            return <div className="capitalize">{status}</div>
        }

    },


    {
        accessorKey: "createdAt",
        header: "Creation Date",
        cell: ({ row }) => (
            <div></div>
            // <div className="capitalize">{format(row.getValue("createdAt"), 'MMM d, yyy h:mm a')}</div>
        ),


    },

    // {
    //     id: "actions",
    //     header: "Action",
    //     enableHiding: false,
    //     cell: (data) => {
    //         return (
    //             <DropdownMenu>
    //                 <DropdownMenuTrigger asChild>
    //                     <Button variant="ghost" className="h-8 w-8 p-0">
    //                         <span className="sr-only">Open menu</span>
    //                         <MoreHorizontal className="h-4 w-4" />
    //                     </Button>
    //                 </DropdownMenuTrigger>
    //                 <DropdownMenuContent align="end">
    //                     <DropdownMenuLabel>Actions</DropdownMenuLabel>
    //                     <DropdownMenuSeparator />
    //                     <DropdownMenuItem onClick={() => {
    //                         let userId = data.row.original?._id
    //                     }}>Suspend</DropdownMenuItem>
    //                     <DropdownMenuItem onClick={() => {
    //                         let userId = data.row.original?._id
    //                     }}>Remove</DropdownMenuItem>
    //                 </DropdownMenuContent>
    //             </DropdownMenu>
    //         )
    //     },
    // },
]

const recentReportsColumns = [
  {
      accessorKey: "postId",
      header: "Post Id",
      cell: ({ row }) => (
          <div className="capitalize">{row.original?.postId}</div>
      ),
  },
  {
      header: "User Id",
      cell: ({ row }) => {

        console.log(row.original) 
          return <div>{row.original?.reportedBy ? row.original?.reportedBy  : "deleted user"}</div>
        }
  },

  {
      header: "Report Message",
      cell: ({ row }) => (
          <div>{row.original.reportMessage.slice(0, 24)}...</div>
      ),
  },

  {
      accessorKey: "createdAt",
      header: "Reported On",
      cell: ({ row }) => (
          <div className="capitalize">{format(row.getValue("createdAt"), 'MMM d, yyy h:mm a')}</div>
      ),


  },
]

function DashboardSection() {
  const { data, isLoading, isSuccess, isError} = useDashboardData()
  console.log(data)

  return (
    <main className="w-full px-8 py-4 overflow-y-scroll">
      <div className="campaign_cards-container" >
        <div className='campaign_cards'>
          <SimpleCard title={'Total Users'} content={isSuccess && (data.counters["users"] && data.counters["users"][0]?.count) || 0} />
          <SimpleCard title={'Total Reports'} content={isSuccess && (data.counters["reports"] && data.counters["reports"][0]?.count) || 0} />

        </div>
        <div className='campaign_cards'>
          <SimpleCard title={'Total Campaigns'} content={isSuccess && (data.counters["campaigns"] && data.counters["campaigns"][0]?.count) || 0} />
          <SimpleCard title={'Total Revenue'} content={isSuccess && (data.counters["revenue"] && data.counters["revenue"][0]?.count) || 0} />

        </div>


      </div>
      <AdminDataTable  title={"Recent Campaigns"} filter={false} columns={campaignColumns} data={!isLoading && data.latestCampaigns} />
      <AdminDataTable title={"Recent Reports"} filter={false} columns={recentReportsColumns} data={!isLoading && data.latestReports} />
    </main>
  )
}

export default DashboardSection