export enum HTTP_CONTENT_TYPES {
    MULTIPART_FORM_DATA = 'multipart/form-data'
} 

export enum CallTypes {
    AUDIO = "Audio",
    VIDEO = "Video"
}

export enum CallStates {
    NEUTRAL = "NEUTRAL",
    CALLING = "CALLING",
    ACCEPTED = "ACCEPTED",
}
