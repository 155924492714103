import {  RouterProvider } from 'react-router-dom'
import { router } from './router'
import { ThemeProvider } from './components/ThemeProvider'
import Toast from './components/Toast'

function App() {

  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
        <Toast/>
      </ThemeProvider>
    </>
  )
}

export default App