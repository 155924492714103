import ImageCropper from "@/components/ImageCropper"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { copyToClipboard } from "@/lib/copyToClipboard"
import { Avatar, AvatarFallback, AvatarImage } from "@radix-ui/react-avatar"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, Label } from "@radix-ui/react-dropdown-menu"
import { format } from "date-fns"
import { Camera, ChevronLeft, Copy, EllipsisVertical } from "lucide-react"
import { FC,  useState } from "react"
import { MdClose } from "react-icons/md"
import { RiAdminLine, RiUserUnfollowLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"


const ChatGroup: FC<any> = ({ groupDetails }) => {
    const [isCopied, setIsCopied] = useState(false)
    const navigate = useNavigate()

    return (
        <div className='absolute top-0 right-0 w-screen z-10 sm:p-8 overflow-hidden h-screen flex items-center justify-center'>
            <div className='absolute top-0 right-0 backdrop-blur-[1.5px] w-full h-full' onClick={() => {
                navigate('', {replace: true})
            }}></div>
            <div className='z-10 max-w-[460px] w-full bg-background rounded-lg h-full  border-2 border-accent overflow-auto'>
                <div className="grid gap-8 p-1  overflow-y-auto relative">
                    <div className="flex flex-col items-center justify-center relative">
                        {/* cover image */}
                        <div className='relative w-full max-h-72 roundd-md  overflow-hidden'>
                        <MdClose className="sm:hidden float-end" size={24} cursor="pointer" onClick={() => {
                        navigate('', {replace: true})
                    }} />
                            <div className="w-full">
                                <label htmlFor="image-cover" className="cursor-pointer">
                                    <div className="flex items-center justify-center w-full h-56 bg-muted">
                                        {
                                            groupDetails?.cover ? <img className='w-full' onClick={() => {
                                            }} src={groupDetails?.cover} alt="" />

                                                :
                                                <Camera size={42} />

                                        }
                                    </div>

                                </label>
                            </div>

                        </div>
                        {/* profile image */}
                        <div className='w-28 h-28 rounded-xl absolute -bottom-16 border-primary border-2 overflow-hidden'>
                            <div >
                                <label htmlFor="image-profile" className="cursor-pointer">
                                    <div className="flex items-center justify-center w-28 h-28 bg-muted">
                                            { groupDetails?.profile ? <img className='w-full' onClick={() => {
                                            }} src={groupDetails?.profile} alt="" />

                                                :
                                                <Camera size={42} />
                                        }
                                    </div>

                                </label>
                            </div>

                        </div>
                    </div>
                    <form onSubmit={(e)=> {
                        e.preventDefault()
                    }}>
                        <div className="w-full p-4 flex justify-center flex-col gap-8 items-center">
                            <div className="flex flex-col w-full items-center justify-center gap-2 mt-10">
                            <div className="max-w-96 w-full">
                            <Label className="flex gap-2 py-2">
                                        <span>Group Id</span>
                                        <Copy size={20} cursor="pointer" onClick={() => {
                                            if (!isCopied) {
                                                copyToClipboard(groupDetails?._id, setIsCopied)
                                                setIsCopied(true)
                                                return
                                            }
                                        }} />
                                    </Label>
                                    <Input
                                        name="id"
                                        defaultValue={groupDetails?._id}
                                        disabled={true}
                                        id="id"
                                        className="w-96"
                                        placeholder="Page Id"
                                    />
                                </div>

                                <div className="max-w-96 w-full">
                                    <Label >
                                        Name
                                    </Label>
                                    <Input
                                        name="name"
                                        defaultValue={groupDetails?.name}
                                        disabled={true}
                                        id="name"
                                        className="w-96"
                                        placeholder="Page Name"
                                    />
                                </div>


                                <div className="max-w-96 w-full">
                                    <Label >
                                        Created At
                                    </Label>
                                    <Input
                                        name="createdat"
                                        defaultValue={format(groupDetails.createdAt, 'MMM d, yyy h:mm a')}
                                        disabled={true}
                                        id="createdat"
                                        className="w-96"
                                        placeholder="CreatedAt"
                                    />
                                </div>
                                <div className="max-w-96 w-full">
                                    <Label >
                                        Description
                                    </Label>
                                    <Textarea
                                        defaultValue={groupDetails?.description}
                                        id="description"
                                        name="description"
                                        disabled={true}
                                        className="w-full"
                                        placeholder="group description"
                                        maxLength={150}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChatGroup