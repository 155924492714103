import { toast } from "react-toastify";

export const copyToClipboard = async (text, setIsCopied) => {
    try {
        await navigator.clipboard.writeText(text);
        toast.success("Copied...")
        setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
        toast.error(err.message)
    }
};
