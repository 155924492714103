import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { copyToClipboard } from "@/lib/copyToClipboard"
import { Label } from "@radix-ui/react-dropdown-menu"
import { format } from "date-fns"
import { Camera, Copy } from "lucide-react"
import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"


const GroupModel: FC<any> = ({ setModelTrigger, groupDetails }) => {
    const navigate = useNavigate()
    const [isCopied, setIsCopied] = useState(false);

    return (
        <div className='absolute top-0 right-0 w-screen z-50 sm:p-8 overflow-hidden h-screen flex items-center justify-center'>
            <div className='absolute top-0 right-0 backdrop-blur-[1.5px] w-full h-full' onClick={() => {
                setModelTrigger(false)
                navigate("", { replace: true })
            }}></div>
            <div className='z-10 w-fit bg-background rounded-lg h-full  border-2 border-accent overflow-hidden'>
                <div className="grid gap-8 p-1  relative">
                    <div className="flex flex-col items-center justify-center relative">
                        {/* cover image */}
                        <div className='relative max-w-[460px] max-h-72 roundd-md  overflow-hidden'>
                            <div >
                                <label htmlFor="image-cover">
                                    <div className="flex items-center justify-center w-[440px] h-56 bg-muted">
                                        {
                                            groupDetails?.cover ? <img className='w-full' onClick={() => {
                                            }} src={groupDetails?.cover} alt="" />
                                                :
                                                <Camera size={42} />
                                        }
                                    </div>

                                </label>
                            </div>

                        </div>
                        {/* profile image */}
                        <div className='w-28 h-28 rounded-xl absolute -bottom-16 border-primary border-2 overflow-hidden'>
                            <div >
                                <label htmlFor="image-profile">
                                    <div className="flex items-center justify-center w-28 h-28 bg-muted">
                                        {
                                            groupDetails?.profile ? <img className='w-full' onClick={() => {
                                            }} src={groupDetails?.profile} alt="" />
                                                :
                                                <Camera size={42} />
                                        }
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                    }} 
                    >
                        <div className="w-full p-4 flex flex-col gap-8 items-center">
                            <div className="flex flex-col w-full justify-center gap-2 mt-10">
                                <div>

                                    <Label className="flex gap-2 py-2">
                                        <span>Group Id</span>
                                        <Copy size={20} cursor="pointer" onClick={() => {
                                            if (!isCopied) {
                                                copyToClipboard(groupDetails?._id, setIsCopied)
                                                setIsCopied(true)
                                                return
                                            }
                                        }} />
                                    </Label>
                                    <Input
                                        name="id"
                                        defaultValue={groupDetails?._id}
                                        disabled={true}
                                        id="id"
                                        className="w-96"
                                        placeholder="Page Id"
                                    />
                                </div>

                                <div>
                                    <Label >
                                        Name
                                    </Label>
                                    <Input
                                        name="name"
                                        defaultValue={groupDetails?.name}
                                        disabled={true}
                                        id="name"
                                        className="w-96"
                                        placeholder="Page Name"
                                    />
                                </div>


                                <div>
                                    <Label >
                                        Created At
                                    </Label>
                                    <Input
                                        name="createdat"
                                        defaultValue={format(groupDetails.createdAt, 'MMM d, yyy h:mm a')}
                                        disabled={true}
                                        id="createdat"
                                        className="w-96"
                                        placeholder="CreatedAt"
                                    />
                                </div>
                                <div>
                                    <Label >
                                        Group Handle
                                    </Label>
                                    <Input
                                        name="handle"
                                        defaultValue={groupDetails?.handle}
                                        disabled={true}
                                        id="handle"
                                        className="w-96"
                                        placeholder="@your_group_handle"
                                    />
                                </div>
                                <div>
                                    <Label >
                                        Group Bio
                                    </Label>
                                    <Input
                                        name="bio"
                                        defaultValue={groupDetails?.bio}
                                        disabled={true}
                                        id="bio"
                                        className="w-96"
                                        placeholder="bio"
                                    />
                                </div>

                                <div>
                                    <Label >
                                        Description
                                    </Label>
                                    <Textarea
                                        defaultValue={groupDetails?.description}
                                        disabled={true}
                                        id="description"
                                        name="description"
                                        className="w-96"
                                        placeholder="group description"
                                        maxLength={150}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default GroupModel