import { axiosClient } from "../axiosClient"

export const fetchChatGroups = async (param: string, search: string) => {
    console.log(search, 'chatgroups')
    const { data } = await axiosClient.get('/admin/chatgroups', { params: { cursor: param, search } })
    return data
}

export const removeChatGroup = async (groupId: string) => {
    console.log(groupId, 'removechatgroup id')
    const { data } = await axiosClient.post("/admin/chatgroup/remove", { groupId })
    console.log(data)
    return data
}
